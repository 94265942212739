<template>
  <div>
    <v-snackbar
      :color="notification.type"
      :timeout="notification.timeout"
      v-model="showSnackbar"
      transition="dialog-bottom-transition">
      <span v-html="notification.text"></span>
      <v-btn color="white" flat @click="showSnackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { VSnackbar, VBtn } from 'vuetify/lib';

import { getErrorMessage } from 'global-utils';

export default {
  components: {
    VSnackbar,
    VBtn,
  },
  constants: {
    defaultTimeout: 3000,
  },
  data() {
    return {
      notification: {
        text: '',
        type: '',
        timeout: this.defaultTimeout,
      },
      showSnackbar: false,
    };
  },
  methods: {
    notify(notification, type, timeout = this.defaultTimeout) {
      if (!notification) {
        return;
      }

      let text;
      if (typeof notification === 'string') {
        text = notification;
      } else if (notification instanceof Error) {
        text = getErrorMessage(notification);

        // * Avoid custom and api errors
        if (notification.name !== 'Error') {
          window.setTimeout(() => {
            throw notification;
          });
        }
      }

      this.notification = {
        type,
        text,
        timeout,
      };
      this.showSnackbar = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.width-auto {
  width: auto;
}
</style>
