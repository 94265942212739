import { notify } from 'utils/notify';
import { setSystemTz } from 'tz-utils';
import { get as getConfig } from 'hcms-transforms/config';

async function setDefaultTimezone() {
  try {
    const configs = await getConfig(['CLIENT_TIMEZONE']);

    const { CLIENT_TIMEZONE } = configs;

    if (!CLIENT_TIMEZONE) {
      throw new Error('Config not received');
    }
    const timezone = CLIENT_TIMEZONE.getValue();
    setSystemTz(timezone);
  } catch (error) {
    notify('Unable to retrieve valid timezone, setting system timezone as UTC', 'error');
    setSystemTz();
  }
}

export default setDefaultTimezone;
