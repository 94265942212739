<template>
  <div class="mask">
    <v-progress-circular color="primary" :width="2" :indeterminate="true"></v-progress-circular>
  </div>
</template>
<script>
import { VProgressCircular } from 'vuetify/lib';

export default {
  name: 'Overlay',
  components: {
    VProgressCircular,
  },
};
</script>
<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(white, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
