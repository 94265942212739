import axios from 'axios';

function transformRequest(request) {
  return {
    email_proof: {
      email_proof_token: request.token,
      dob: request.dob,
      new_password: request.password,
    },
  };
}

async function post(emailProofId, verification) {
  const url = `api/email_proof/${emailProofId}/verify`;
  const requestData = transformRequest(verification);
  return axios.post(url, requestData);
}

export { transformRequest, post };
