import axios from 'axios';

function transformRequest(emailProof) {
  return {
    email_proof: {
      email: emailProof.email,
      email_proof_purpose_mc_id: emailProof.purpose,
    },
  };
}

async function post(data) {
  const url = 'api/email_proof';
  const requestData = transformRequest(data);
  const response = await axios.post(url, requestData);
  return response.data.email_proof_id;
}

export { transformRequest, post };
