<template>
  <v-card class="elevation-12 login-card" v-loading="loading">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title> HCMS Login </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-text-field
        name="email"
        v-validate="validations.required"
        :error-messages="errors.first('email')"
        label="Email"
        v-model="email"
        @keyup.enter="login()"
        type="text"></v-text-field>
      <v-text-field
        name="password"
        v-validate="validations.required"
        :error-messages="errors.first('password')"
        label="Password"
        v-model="password"
        @keyup.enter="login()"
        type="password"></v-text-field>
      <router-link class="text--secondary body-2" :to="{ name: 'Forgot Password' }">
        Forgot your password?
      </router-link>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn flat color="primary" @click="login()">Login</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { post as login } from 'hcms-transforms/login';
import { storeUserCookies, routeToDashboard, clearUserCookies } from 'auth-utils';

export default {
  name: 'Login',

  constants: {
    validations: {
      required: 'required',
    },
  },

  data() {
    return {
      email: '',
      password: '',

      loading: false,
    };
  },

  methods: {
    async login() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      this.loading = true;

      try {
        const credentials = {
          email: this.email,
          password: this.password,
        };
        const user = await login(credentials);
        storeUserCookies(user);
        routeToDashboard();
      } catch (err) {
        this.$notify(err, 'error');
        clearUserCookies();
      }

      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.login-card {
  width: 480px;
}
</style>
