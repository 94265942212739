import Vue from 'vue';
import Router from 'vue-router';
import Login from 'views/Login.vue';
import ForgotPassword from 'views/ForgotPassword.vue';
import ProveEmail from 'views/ProveEmail.vue';
import { currentUser, routeToDashboard } from 'auth-utils';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter(to, from, next) {
      if (currentUser.isAuthenticated) {
        routeToDashboard();
      } else {
        next();
      }
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
  },
  {
    path: '/prove-email',
    name: 'Prove Email',
    component: ProveEmail,
  },
  {
    path: '*',
    redirect: {
      name: 'Login',
    },
  },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.

 function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
  };

  * */

const router = new Router({
  routes,
  mode: 'history',
});

export default router;
