import Vue from 'vue';
import NotifyContainer from './NotifyContainer.vue';

const notifyContainerEl = document.createElement('div');

document.body.appendChild(notifyContainerEl);

const defaultTimeout = 3000;
const errorTimeout = 7000;

const getTimeout = (type) => (type === 'error' ? errorTimeout : defaultTimeout);

const notifyContainer = new Vue(NotifyContainer).$mount(notifyContainerEl);

function notify(text, type, timeout) {
  return notifyContainer.notify(text, type, timeout || getTimeout(type));
}

const Plugin = {
  install() {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$notify = notify;
  },
};

export default Plugin;

export { Plugin, notify };
