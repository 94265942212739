import 'material-design-icons-iconfont/dist/material-design-icons.css';

import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueConstants from 'vue-constants';
import Vuetify, {
  VApp,
  VContent,
  VContainer,
  VLayout,
  VSpacer,
  VForm,
  VTextField,
  VBtn,
  VExpandTransition,
  VToolbar,
  VToolbarTitle,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
} from 'vuetify/lib';
import setDefaultTimezone from 'setup/timezone';

import axios from 'axios';
import router from 'router';

import 'vuetify/src/stylus/app.styl';
import '@/assets/scss/hcms.scss';

import Notify from 'utils/notify';
import Loading from '@/directives/Loading';
import ShowModal from 'vue-modal-promise';

import { SERVER_ADDRESS } from 'hcms-constants/APP_PATHS';

import App from './App.vue';

Vue.use(VeeValidate);
Vue.use(VueConstants);
Vue.use(Notify);
Vue.use(Loading);
Vue.use(ShowModal);

Vue.use(Vuetify, {
  components: {
    VApp,
    VContent,
    VContainer,
    VLayout,
    VSpacer,
    VForm,
    VTextField,
    VBtn,
    VExpandTransition,
    VToolbar,
    VToolbarTitle,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
  },
});

Vue.config.productionTip = false;

axios.defaults.baseURL = SERVER_ADDRESS;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const promises = [setDefaultTimezone()];

Promise.all(promises).then(() => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    render: (h) => h(App),
    router,
  });
});
