<template>
  <v-card class="elevation-12 forgot-password-card" v-loading="loading">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title> Forgot your password? </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-text-field
        name="Email"
        v-validate="rules.email"
        :error-messages="errors.first('Email')"
        label="Email Address"
        v-model="email"
        @keyup.enter="sendEmailVerification()"
        type="text"></v-text-field>
      <router-link class="body-2 text--secondary back-to-login" :to="{ name: 'Login' }">
        Back to Login
      </router-link>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn flat color="primary" @click="sendEmailVerification()">Send Verification Mail</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { emailProofPurposeUtils } from 'hcms-mc-utils';
import { post as sendEmailVerification } from 'hcms-transforms/email_proof';

const CHANGE_PASSWORD = emailProofPurposeUtils.toValue('Change Password');

export default {
  name: 'ForgotPassword',

  constants: {
    rules: {
      email: 'required',
    },
  },

  data() {
    return {
      loading: false,
      email: '',
    };
  },

  methods: {
    async sendEmailVerification() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        return;
      }

      this.loading = true;

      try {
        await sendEmailVerification({
          email: this.email,
          purpose: CHANGE_PASSWORD,
        });
        this.$notify('A password reset link has been sent to your email.');
        this.$router.push({ name: 'Login' });
      } catch (error) {
        this.$notify(error, 'error');
      }

      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.forgot-password-card {
  width: 480px;
}
</style>
