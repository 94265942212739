<template>
  <v-card class="elevation-12 forgot-password-card" v-loading="loading">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        {{ state.title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          v-if="isEmailVerification && !isNok"
          class="required"
          name="Date of Birth"
          :error-messages="errors.first('Date of Birth')"
          :value="formatDate(emailProof.dob)"
          v-validate="rules.dob"
          label="Date of Birth"
          @click="selectDob()"
          prepend-inner-icon="event"
          readonly>
        </v-text-field>
        <v-text-field
          name="Password"
          v-validate="rules.password"
          :error-messages="errors.first('Password')"
          label="New Password"
          v-model="emailProof.password"
          type="password"></v-text-field>
        <v-text-field
          name="Confirm Password"
          v-validate="rules.confirmPassword"
          :error-messages="errors.first('Confirm Password')"
          label="Confirm New Password"
          v-model="emailProof.confirmPassword"
          @keyup.enter="verifyProof()"
          type="password"></v-text-field>
      </v-form>
      <router-link class="text--secondary body-2" :to="{ name: 'Login' }">
        Back to Login
      </router-link>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn flat color="primary" @click="verifyProof()">{{ state.primaryBtn.label }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { post as verifyEmailProof } from 'hcms-transforms/email_proof/verify';
import moment from 'moment';
import { formatDate } from 'global-utils';
import { emailProofPurposeUtils } from 'hcms-mc-utils';

import DateModal from '@/views/DateModal.vue';

const EMAIL_VERIFICATION = emailProofPurposeUtils.toValue('Email Verification');

export default {
  name: 'ChangePassword',

  constants: {
    formatDate,
  },

  data() {
    return {
      loading: false,
      emailProof: {
        dob: '',
        password: '',
        confirmPassword: '',
      },
    };
  },

  computed: {
    isEmailVerification() {
      return Number(this.$route.query.purpose) === EMAIL_VERIFICATION;
    },
    isNok() {
      return this.$route.query.is_nok === 'True';
    },
    rules() {
      return {
        password: 'required',
        confirmPassword: {
          required: true,
          is: this.emailProof.password,
        },
        dob: 'required',
      };
    },
    state() {
      const dob = {
        visible: true,
      };
      const primaryBtn = {
        label: 'Update',
      };
      const state = {
        dob,
        primaryBtn,
        title: '',
      };

      dob.visible = this.isEmailVerification;
      primaryBtn.label = this.isEmailVerification ? 'Verify' : 'Change Password';
      state.title = emailProofPurposeUtils.toLabel(Number(this.$route.query.purpose));
      return state;
    },
  },

  methods: {
    async selectDob() {
      const TODAY = moment().startOf('day');
      const date = await this.$showModal({
        component: DateModal,
        props: {
          date: this.emailProof.fromDate ? this.emailProof.fromDate.format('YYYY-MM-DD') : '',
          allowedDates: (checkDate) => TODAY.isAfter(checkDate),
          activePicker: 'YEAR',
        },
      });

      if (!date) {
        return;
      }

      this.emailProof.dob = moment(date);
    },
    async verifyProof() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        this.$notify('Please correct all fields!', 'error');
        return;
      }

      this.loading = true;

      try {
        await verifyEmailProof(this.$route.query.id, {
          token: this.$route.query.token,
          password: this.emailProof.password,
          dob: this.isNok ? null : this.emailProof.dob,
        });
        this.$notify('Request Completed!');
        this.$router.push({ name: 'Login' });
      } catch (error) {
        this.$notify(error, 'error');
      }

      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.forgot-password-card {
  width: 480px;
}
</style>
