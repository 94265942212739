<template>
  <div class="date-modal">
    <v-date-picker
      ref="picker"
      :allowed-dates="allowedDates"
      :first-day-of-week="firstDayOfWeek"
      v-model="selectedDate">
      <v-spacer></v-spacer>
      <v-btn @click="selectDate()" flat color="primary">Select</v-btn>
    </v-date-picker>
  </div>
</template>
<script>
import moment from 'moment';

import { VDatePicker } from 'vuetify/lib';

export default {
  name: 'DateModal',
  components: {
    VDatePicker,
  },
  props: ['date', 'allowedDates', 'activePicker'],

  constants: {
    firstDayOfWeek: moment.localeData().firstDayOfWeek(),
  },

  data() {
    return {
      selectedDate: '',
    };
  },

  methods: {
    selectDate() {
      this.$emit('close', this.selectedDate);
    },
  },

  mounted() {
    this.selectedDate = this.date;
    if (this.activePicker) {
      this.$refs.picker.activePicker = this.activePicker;
    }
  },
};
</script>
<style lang="scss" scoped>
.date-modal {
  width: 290px;
}
</style>
