<template>
  <v-app>
    <v-content>
      <v-container fill-height fluid>
        <v-layout align-center justify-center>
          <transition name="scroll-y-transition" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
html {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#app {
  background: linear-gradient(10deg, hsla(123, 38%, 65%, 1), hsla(174, 42%, 65%, 1));
}
</style>
