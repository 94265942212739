import axios from 'axios';

function transformRequest(credentials) {
  return {
    user: {
      email: credentials.email,
      password: credentials.password,
    },
  };
}

function transformResponse(user) {
  return {
    token: user.token,
    role: user.user_group,
    userId: user.user_id,
    email: user.email,
    userName: user.user_name,
    lastLogin: user.last_login,
    isSuperUser: user.is_superuser,
    isFormManager: user.is_form_manager,
  };
}

async function post(credentials) {
  const url = 'api/auth/login';
  const requestData = transformRequest(credentials);
  const response = await axios.post(url, requestData);
  const user = transformResponse(response.data.user);
  return user;
}

export { transformRequest, transformResponse, post };
