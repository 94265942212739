import Overlay from './Overlay.vue';

export default {
  install(Vue) {
    const OverlayComponent = Vue.extend(Overlay);

    function toggleLoading(elem, toggle) {
      const overlay = new OverlayComponent().$mount();

      if (toggle && !elem.overlay) {
        elem.originalStyle = {
          overflow: elem.style.overflow,
          position: elem.style.position,
        };
        elem.style.overflow = 'hidden';
        elem.style.position = 'relative';
        elem.overlay = overlay;
        elem.appendChild(overlay.$el);
        return;
      }

      if (!toggle && elem.overlay) {
        elem.style.overflow = elem.originalStyle.overflow;
        elem.style.position = elem.originalStyle.position;

        elem.removeChild(elem.overlay.$el);
        delete elem.overlay;
      }
    }

    Vue.directive('loading', {
      bind(el, binding) {
        if (binding.value) {
          toggleLoading(el, binding.value);
        }
      },

      update(el, binding) {
        if (binding.oldValue !== binding.value) {
          toggleLoading(el, binding.value);
        }
      },

      unbind(el) {
        toggleLoading(el, false);
        if (el.instance) {
          el.instance.$destroy();
        }
      },
    });
  },
};
